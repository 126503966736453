@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Global Styles */
:root {
  --dark-color: #18181b;
  --white-color: #ffffff;
  --grey-color: #a1a1aa;
  --grey-color-darker: #848487;
  --red-color: rgb(234, 59, 47);
  --red-color-lighter: rgb(230, 92, 82);
  --background-color: #27272b;
}

* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: var(--dark-color);
  color: var(--grey-color);
}

h1,
h2 {
  color: var(--white-color);
  margin: 1rem;
}

h3 {
  color: var(--white-color);
  font-weight: normal;
  margin: 0.5rem;
}

h4 {
  color: var(--grey-color);
  font-weight: normal;
  margin: 0.5rem;
}

h5 {
  color: var(--white-color);
  color: var(--grey-color);
  font-weight: normal;
  margin: 0.5rem;
}

a {
  text-decoration: none;
  color: var(--white-color);
}

i {
  color: var(--red-color);
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

/* Browser compatibility */
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--dark-color) inset !important;
  box-shadow: var(--dark-color);
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--grey-color) !important;
  --webkit-font-size: 1rem !important;
}

/* Padding */
.p {
  padding: 0.5rem;
}

.p-0 {
  padding: 0rem;
}

.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem !important;
}

.m-0 {
  margin: 0rem !important;
}

.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}

.m-4 {
  margin: rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}

.my-4 {
  margin: 4rem 0;
}

.mx {
  margin: 0 0.5rem;
}

.mx-1 {
  margin: 0 1rem;
}

.mx-2 {
  margin: 0 2rem;
}

.mx-3 {
  margin: 0 3rem;
}

.vh50 {
  height: 50vh;
}

.mb-4 {
  margin-bottom: 4rem;
}

/* Utilities */

.content-wrap {
  min-height: 90vh;
}

.container {
  max-width: 1920px;
  margin: 1rem auto;
  overflow: hidden;
  padding: 0 2rem;
  position: relative;
  top: 2.5rem;
}

.border {
  border: 2px solid var(--grey-color);
  border-radius: 0.3rem;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-col {
  flex-direction: column;
}

.hidden {
  display: none;
}

.underline {
  text-decoration: underline;
  color: var(--white-color);
}

/* Text Styles*/
.x-large {
  font-size: 2.5rem;
  line-height: 1.2;
  margin: 2rem 0;
}

.large {
  font-size: 1.8rem;
  line-height: 1.2;
  margin: 2rem 0;
}

.lead {
  font-size: 1.2rem;
  margin: 1.5rem 0;
  color: var(--white-color);
}

.normal {
  font-size: 1rem;
  font-weight: 200;
  margin: 1rem;
}

.italic {
  font-style: italic;
}

.small {
  font-size: 0.8rem;
  color: var(--white-color);
  margin: 0.5rem 0;
}

.text-center {
  text-align: center;
}

.text-dark {
  color: var(--dark-color);
}

.text-grey {
  color: var(--grey-color);
}
.text-white {
  color: var(--white-color);
}

.text-yellow {
  color: var(--red-color);
}

/* Buttons */
.btn {
  display: inline-block;
  background: var(--white-color);
  color: var(--dark-color);
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  margin: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
  border: 2px solid var(--red-color);
  border-radius: 0.5rem;
  transition: transform 0.2s ease-in-out;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-small {
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
  margin: 0;
}

.btn:hover {
  background-color: var(--red-color-lighter);
  color: var(--dark-color);
  transform: scale(0.98);
}

.btn-green:hover {
  background-color: rgb(18, 155, 18);
  color: var(--dark-color);
  transform: scale(0.98);
}

/* Navbar & Section-a Header & Section-c About-me */
#home-header {
  min-height: 100vh;
}
.navbar {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1920px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.7rem 2rem;
  z-index: 100;
  background: var(--dark-color);
  border-bottom: var(--red-color) 1px dotted;
}

.navbar h1 {
  margin: 1rem 0;
}

.navbar ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.navbar .navbar-link {
  color: var(--white-color);
  padding: 0.45rem;
  margin: 0 0.25rem;
  font-size: 1.3rem;
  transition: all 0.2s ease-in-out;
}

.navbar .change.navbar-link {
  color: var(--red-color);
}

.navbar li:hover {
  color: var(--red-color);
  transform: scale(1.1);
  cursor: pointer;
}

.logo {
  font-size: 1.8rem;
  color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo .circle-outer {
  background-color: var(--white-color);
  position: relative;
  width: 1.8rem;
  height: 1.8rem;
  margin: 0.2rem;
  transform: translateY(25%);
  border-radius: 50%;
}

.logo .circle-inner {
  background-color: var(--red-color);
  position: absolute;
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  transform: translateX(50%) translateY(50%);
}

.logo .space {
  margin-right: 1rem;
}

.logo .circle {
  animation: drop-letters 5s 0.1s infinite;
}

@keyframes drop-letters {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(-100%);
  }
  20% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.keyword span:nth-child(1) {
  animation: change-collor 80s 0s infinite;
}
.keyword span:nth-child(2) {
  animation: change-collor 80s 5s infinite;
}
.keyword span:nth-child(3) {
  animation: change-collor 80s 10s infinite;
}
.keyword span:nth-child(4) {
  animation: change-collor 80s 15s infinite;
}
.keyword span:nth-child(5) {
  animation: change-collor 80s 20s infinite;
}
.keyword span:nth-child(6) {
  animation: change-collor 80s 25s infinite;
}
.keyword span:nth-child(7) {
  animation: change-collor 80s 30s infinite;
}
.keyword span:nth-child(8) {
  animation: change-collor 80s 35s infinite;
}
.keyword span:nth-child(9) {
  animation: change-collor 80s 40s infinite;
}
.keyword span:nth-child(10) {
  animation: change-collor 80s 45s infinite;
}
.keyword span:nth-child(11) {
  animation: change-collor 80s 50s infinite;
}
.keyword span:nth-child(12) {
  animation: change-collor 80s 55s infinite;
}
.keyword span:nth-child(13) {
  animation: change-collor 80s 60s infinite;
}
.keyword span:nth-child(14) {
  animation: change-collor 80s 65s infinite;
}
.keyword span:nth-child(15) {
  animation: change-collor 80s 70s infinite;
}
.keyword span:nth-child(16) {
  animation: change-collor 80s 75s infinite;
}

@keyframes change-collor {
  0% {
    font-style: bold;
    color: var(--red-color-lighter);
  }
  10% {
    font-style: normal;
    color: var(--grey-color);
  }
  50% {
    color: var(--white-color);
  }
}

.about-me {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.about-me-header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 1300px;
}

.about-me img {
  border-radius: 0.3rem;
  max-width: 600px;
}

.about-me-header img {
  border-radius: 0.3rem;
  max-width: 450px;
}

.keywords {
  color: var(--white-color);
  font-size: 2.5rem;
  margin: 0 0.5rem;
  height: 600px;
  width: 600px;
  text-align: center;
}

.normal-keywords {
  font-size: 0.9rem;
  margin: 0.25rem;
  color: var(--grey-color);
}

.about-me-text {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about-me-photos {
  margin: 0 auto;
}

/* Section B: My Projects */

.zindex {
  z-index: -1;
}

.project-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-items: center;
  background: var(--dark-color);
  border-radius: 0.5rem;
  padding: 0 1rem;
}

.project {
  position: relative;
  overflow: hidden;
  margin: 1rem;
  width: 25rem;
  height: auto;
}

.project::after {
  content: "";
  position: absolute;
  display: block;
  background: var(--dark-color);
  opacity: 0.9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(2) translateX(-75%) translateY(-75%) rotate(-28deg);
  transition: transform 3s cubic-bezier(0.2, 1, 0.3, 1);
}

.project:hover:after {
  transform: scale(2) translateX(0) translateY(0) rotate(-28deg);
}

.project:hover .project-image {
  transform: scale(1.2);
}

.project:hover .project-text {
  opacity: 1;
  transform: translateY(0);
}

.project-image {
  height: 100%;
  transform: translateZ(0);
  display: block;
  transition: transform 750ms cubic-bezier(0.2, 1, 0.3, 1);
}

.project-image:before {
  content: "";
  display: block;
  padding-top: 75%;
  overflow: hidden;
}

.project-image img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: auto;
  line-height: 0;
}

.project-image .course-name {
  font-size: 1.2rem;
  position: absolute;
  bottom: 15%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: auto;
  margin: 0;
}

.project-text {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  text-align: center;
  z-index: 1;
  color: #fff;
  transform: translateY(-20%);
  transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1),
    transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
  transition-delay: 300ms;
}

.project-text-wrap {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.project-links-wrap {
  position: absolute;
  width: 100%;
  bottom: 0%;
}

.project-link {
  width: 42%;
  margin: 0rem 0.5rem 0rem 0.5rem;
}

/* Section-d  References */
.references-wrapper {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
}

.references {
  border: 1px solid var(--grey-color);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  width: 15rem;
  height: 35rem;
  overflow-x: auto;
  margin: 1rem;
  flex-shrink: 0;
  border-radius: 5px;
}

.image-container {
  width: 120px;
  height: 120px;
  margin: 0 auto;
}

.image-container img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

/* Course details */

.course-main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.course-main-text {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.course-main-text p {
  margin-top: 0;
}

.course-main img {
  border-radius: 0.3rem;
  max-width: 600px;
  margin: 0 auto;
}

/* Contact form */
.contact-form {
  max-width: 1920px;
  margin: 1rem auto;
  overflow: hidden;
  padding: 0 2rem;
  position: relative;
  top: 5rem;
}

/* Footer */

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;
  width: 100%;
  bottom: 0rem;
}

.footer-content p {
  margin-left: 1rem;
}

.footer-logo a:hover {
  transform: scale(1.1);
}
.social .fab,
.social .fas,
.social .fa,
.social .fa-solid,
.social .fa-regular {
  margin-right: 1rem;
  border: 2px solid var(--grey-color);
  color: var(--white-color);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  line-height: 20px;
  text-align: center;
  padding: 0.5rem;
}

.social a:hover .fab,
.social a:hover .fas,
.social a:hover .fa,
.social Link:hover .fa-solid,
.social a:hover .fa-regular {
  border: 2px solid var(--red-color);
}

.social a:hover {
  transform: scale(1.1);
}

/* Contact info page */

.contact .lead {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* Cookies */

.cookies-box {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 1rem;
  background-color: var(--grey-color-darker);
  color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  z-index: 1000;
}

/* Mobile Styles */
@media (max-width: 1500px) {
  .about-me {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 1250px) {
  .keywords {
    font-size: 2.2rem;
    height: 600px;
    width: 600px;
    text-align: center;
  }
}

@media (max-width: 1200px) {
  .project-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .about-me-header {
    grid-template-columns: repeat(1, 1fr);
  }

  .about-me-header img {
    max-width: 400px;
  }

  .keywords {
    margin: 0;
  }
}

@media (max-width: 1000px) {
  .course-main {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 750px) {
  .hide-sm {
    display: none;
  }

  .project-wrapper {
    grid-template-columns: 1fr;
  }

  .keywords {
    font-size: 2rem;
    height: 500px;
    width: 500px;
    text-align: center;
  }

  .container {
    top: 4.5rem;
  }

  .project-image .course-name {
    font-size: 1rem;
  }

  /* Text Styles */
  .x-large {
    font-size: 2.25rem;
  }

  .large {
    font-size: 1.7rem;
  }

  .lead {
    font-size: 1.1rem;
  }

  .normal {
    font-size: 1rem;
  }

  /* Navbar */
  .navbar {
    display: block;
    text-align: center;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }

  /* Footer */
  .footer-content {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2rem;
  }
}

@media (max-width: 600px) {
  .small {
    font-size: 0.6rem;
    margin: 0;
  }
}

@media (max-width: 550px) {
  .keywords {
    font-size: 1.8rem;
    height: 400px;
    width: 400px;
    text-align: center;
  }

  /* Text Styles */
  .x-large {
    font-size: 2rem;
  }

  .large {
    font-size: 1.5rem;
  }

  .lead {
    font-size: 1rem;
  }

  .normal {
    font-size: 1rem;
  }

  .navbar .navbar-link {
    font-size: 1rem;
  }
}

@media (max-width: 500px) {
  .keywords {
    font-size: 1.6rem;
    height: 380px;
    width: 380px;
    text-align: center;
  }
}

@media (max-width: 450px) and (orientation: portrait) {
  .mobile {
    height: 60vh;
    padding-top: 20%;
  }

  .about-me-header img {
    max-width: 350px;
  }

  .keywords {
    font-size: 1.4rem;
    height: 360px;
    width: 360px;
    text-align: center;
  }

  .references {
    height: 30rem;
  }

  .container {
    top: 4.75rem;
  }

  .project::after {
    transition: transform 0s cubic-bezier(0.2, 1, 0.3, 1);
  }

  .project-image {
    transition: transform 0ms cubic-bezier(0.2, 1, 0.3, 1);
  }
  .project-text {
    transition: opacity 0ms cubic-bezier(0.2, 1, 0.3, 1),
      transform 0ms cubic-bezier(0.2, 1, 0.3, 1);
    transition-delay: 0ms;
  }
}

@media (max-width: 400px) {
  .container {
    top: 5rem;
  }

  .keywords {
    font-size: 1.2rem;
    height: 330px;
    width: 330px;
    text-align: center;
  }

  /* Form */
  .form-control {
    width: 100%;
  }
  .footer-logo p {
    font-size: 0.7rem;
  }

  .container {
    padding: 0 1rem;
  }
}

@media (max-width: 350px) {
  .container {
    padding: 0 0.25rem;
  }

  .lead {
    width: 90%;
  }

  .keywords {
    height: 300px;
    width: 300px;
    text-align: center;
  }

  /* Text Styles */
  .x-large {
    font-size: 1.5rem;
  }

  .large {
    font-size: 1rem;
  }

  .lead {
    font-size: 0.8rem;
  }

  .normal {
    font-size: 0.8rem;
  }

  .references {
    width: 10rem;
  }
  .project-image .course-name {
    font-size: 1rem;
    bottom: 5%;
  }
}
